#navbar {
    /* transition: background-color 0.5s ease; */
    background-color:rgba(48, 96, 62, 1);
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#navLinkContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    width: 75%;
}

.scrolled {
    background-color: rgba(48, 96, 62, 1);
}

#navbarContainer {
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
}

#headerText {
    color: white;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    margin: .5vw 0 0 1vw;
    text-decoration: none;
}

#mcareHeader {
    font-size: 50px;
}

#bldgServHeader {
    font-size: 25px;
}

#mcareHeader,
#bldgServHeader {
    padding-left: .5vw;
}

.navLink,
.navLinkBold {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgba(255, 255, 255, 1);
    /* color: #4b9560; */
    height: 3.5vw;
    width: 140px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.15vw;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
    margin: 0 1.5vw 8px 0;
    margin-bottom: 1.5vw;
}

.navLinkBold {
    background-color: rgba(48, 96, 62, 1);
    width: 12vw;
}

.navLink:hover {
    background-color: rgba(48, 96, 62, 1);
}

.hamburger-icon {
    display: flex;
    justify-content: center;
    gap: 5vw;
    width: 100%;
}

#mcareHeader,
#bldgServHeader,
.navLink {
    opacity: 1;
}

@media only screen and (max-width: 575.98px) {
    #navbar {
        height: 10vw;
        background-color: rgba(48, 96, 62, 1);
        transition: none;
    }

    .navLinkBold {
        margin-right: 3vw;
        width: 20vw;
    }

    .navLink,
    .navLinkBold {
        margin-bottom: 3vw;
        width: 8vw;
    }

    #navLinkContainer {
        display: none;
    }
    
    #navLinkContainer.show {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8vw;
        background-color: rgba(48, 96, 62, 1);
        width: 110%;
        height: 69.5vw;
        padding-top: 1vw;
    }
    
    .navLink, .navLinkBold {
        margin: 0 auto;
        font-size: 4vw;
        border-bottom: thin solid white;
        width: 75%;
        border-radius: 0;
        padding-bottom: 4vw;
    }

    .hamburger-icon {
        display: block;
        cursor: pointer;
        padding: .75vw 3vw 0 0;
    }

    .bar {
        width: 25px;
        height: 3px;
        background-color: white;
        margin: 6px 0;
        transition: 0.4s;
        margin-left: auto;
    }
}