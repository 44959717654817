img, .imgRight {
    height: 350px;
    width: 350px;
    margin: 15px 15px 15px 0;
}

.imgRight {
    margin: 15px 0 15px 15px;
}

.descriptionContainer, .descriptionContainerRight {
    padding: 30px 130px 30px 130px;
}

.descriptionContainer {
    margin-top: 43vw;
}

.innerContainer, .innerContainerRight {
    display: flex;
}

.innerContainerRight {
    flex-direction: row-reverse;
}

.description, .descriptionRight {
    padding-top: 45px;
    font-size: 22px;
    width: 50%;
    line-height: 35px;
}

.descriptionRight {
    text-align: right;
}

.descriptionTitle, .descriptionTitleRight {
    font-size: 30px;
    position: relative;
    font-weight: bold;
}

.descriptionTitle {
    text-align: left;
    margin-bottom: 20px;
}

.descriptionTitleRight {
    text-align: right;
}

form {
    display: flex;
}

label {
    font-size: 1.5vw;
}

@media only screen and (max-width: 575.98px) {
    .descriptionContainer, .descriptionContainerRight {
        width: 100vw;
        padding: 1vw;
    }

    img, .imgRight {
        height: 40vw;
        width: 40vw;
        margin-top: 1vw;
    }

    .descriptionContainer {
        margin: 25vw 0 0 3vw;
    }

    .descriptionTitle, .descriptionTitleRight {
        font-size: 4vw;
    }

    .descriptionTitle {
        margin-bottom: 0;
    }

    .description, .descriptionRight {
        font-size: 4vw;
        line-height: 5vw;
        padding-top: 1vw;
    }

    .descriptionRight {
        margin-bottom: 2vw;
    }
}