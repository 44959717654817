#contactContainer {
    display: flex;
    align-items: flex-start;
    padding: 60px;
    background-color: #E5EEE4;
    width: 92%;
    height: 36vw;
    margin: 70px 30px 30px 30px;
    border-radius: 15px;
    color: rgb(70, 70, 70);;
}

#contactInfoContainer {
    margin-left: 40px;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    font-size: 28px;
}

#contactMcare {
    font-size: 48px;
    font-weight: 550;
}

.managerCard {
    border-radius: 10px;
    background-color: #E5EEE4;
    height: 190px;
    width: 265px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    padding: 0 10px 10px 10px;
}

.managerContactContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4px;
    background-color: #E5EEE4;;
}

.managerName {
    text-align: center;
    padding-top: 5px;
    font-size: 30px;
    font-weight: bold;
}

.managerTitle {
    font-size: 14px;
    text-align: center;
}

.managerName, .managerTitle {
    color: rgb(70, 70, 70);
}

.nameContainer {
    margin-bottom: 6px;
}


#managerCardContainer {
    display: flex;
    gap: 10px;
    margin: 50px 0 100px 0;
    flex-wrap: wrap;
    justify-content: center;
}

#addMgrForm {
    font-size: 1.5vw;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 3vw;
}

#formInputContainer {
    display: flex;
    flex-direction: column;
}

#addMgrButton {
    width: 8vw;
    font-size: 2vw;
}

#addMgrContainer {
    background-color: rgb(185, 183, 183);
    height: 10vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    align-items: center;
    width: 100%;
}

.emailImage {
    width: 32px;
    height: 32px;
    margin: 0 5px 0 0;

}

.contactInfo {
    display: flex;
    align-items: center;
    width: 100%;
    align-self: flex-end;
    height: 38px;
    justify-content: center;
    place-self: center;
    background-color: white;
    border-top: 2px solid #E5EEE4;
    border-radius: 10px;
}

.contactLineContainer {
    display: flex;
    flex-direction: row;
    font-size: 18px;
}

#managerHomePage {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
}

#contactMainPhone {
    font-size: 18px;
    margin-left: .3vw;
}

a {
    text-decoration: underline;
    color: black;
    font-size: 14px;
}

@media only screen and (max-width: 575.98px) { 
    #contactMcare {
        font-size: 6vw;
        margin: 3vw 0;
    }

    .addressLine {
        font-size: 7vw;
        margin-top: 0;
    }

    .addressLine2 {
        font-size: 6vw;
    }

    #contactContainer {
        flex-direction: column;
        padding: 5vw;
        justify-content: center;
        align-items: center;
        margin-top: 8vw;
        height: 95vw;
        margin-bottom: 6vw;
    }

    #contactInfoContainer {
        margin-top: 7vw;
        gap: .5vw;
        margin-left: 0;
        justify-content: center;
        align-items: center;
    }

    #addressContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #contactMainPhone {
        text-align: center;
    }

    #managerCardContainer {
        margin: 0 0 4vw 0;
    }

    .managerCard {
        height: 34vw;
    }

    .managerName {
        font-size: 6vw;
    }

    .managerTitle {
        font-size: 3vw;
    }
}