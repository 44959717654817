#footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: rgba(48, 96, 62, 1);
    height: 9vh;
    color: white;
    align-items: flex-end;
    gap: 50px;
    padding: 0 35px 12px 20px;
    position: static;
    bottom: 0;
}

#mastercareText {
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
}

#mcare {
    font-size: 46px;
    margin-bottom: 0;
}

#bldgServ {
    font-size: 16px;
    margin-top: -8px;
}

#addressAndPhone {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    justify-content: flex-end;
}

#phone {
    background-color: white;
    color: rgba(48, 96, 62, 1);
    height: 33px;
    width: 75%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
    margin-bottom: 2px;
}

#address {
    font-size: 15px;
}

#phoneImg {
    width: 1.75vw;
    height: 1.75vw;
    margin: 0 .5vw 0 0;
}

@media only screen and (max-width: 575.98px) {

    #mcare {
        font-size: 8vw;
    }

    #bldgServ {
        font-size: 4vw;
    }

    #address {
        font-size: 2vw;
    }

    #phone {
        font-size: 3vw;
        height: 6.5vw;
        width: 85%;
    }

    #footer-container {
        padding: 0 2vw 2vw 2vw;
        height: 13vw;
    }

    #phoneImg {
        width: 5.5vw;
        height: 5.5vw;
        margin: 0 1vw 0 0;
    }
}