.aboutDescriptionTitle {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

.aboutDescription {
    font-size: 21px;
    margin-bottom: 20px;
}

.aboutDescriptionContainer {
    padding: 30px 400px 0 400px;
}

.aboutImg {
    width: 250px;
    height: 250px;
}

#aboutImgContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
}

.input {
    height: 5vw;
    width: 20vw;
    word-wrap: break-word;
    word-break: break-all;
}

form {
    display: flex;
}

label {
    font-size: 1.5vw;
}

@media only screen and (max-width: 575.98px) { 
    .aboutDescriptionTitle {
        font-size: 5vw;
        margin: 2vw 0 0 0;
    }

    .aboutDescription {
        font-size: 3.5vw;
        margin-bottom: 1vw;
    }

    .aboutDescriptionContainer {
        padding: 0 12vw;
    }

    .aboutImg {
        height: 23vw;
        width: 23vw;
        margin: 1vw;
    }

    #aboutImgContainer {
        grid-gap: .75vw;
        margin-top: 3vw;
    }
}