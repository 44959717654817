.subLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgba(48, 96, 62, 1);
    background-color: white;
    height: 4vw;
    width: 150px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5vw;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
    margin-bottom: 8px;
}

#subLinkContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 55px;
}

#header {
    background-image: url('/src/assets/grove.jpg');
    background-size: 100% 100%; /* Stretch the width, maintain aspect ratio for height */
    background-position: center center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vw;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#bldgServHeader {
    margin-top: -15px;
}

#subtitle {
    text-align: center;
    bottom: 0;
    color: white;
    font-family: 'Calibri', sans-serif;
    font-weight: bold;
    font-size: 60px;
    margin-top: 35vw;
}

#subtitle2 {
    text-align: center;
    bottom: 0;
    color: white;
    font-family: 'Calibri', sans-serif;
    font-size: 30px;
}

#subHeaderContainer {
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0;
    /* margin-top: -6.7vw; */
    z-index: 0;
    background-color: #4b9560;
    width: 100%;
    background-image: url('/src/assets/grove.jpg');
    background-size: 100% auto; /* Stretch the width, maintain aspect ratio for height */
    background-position: center center;
    background-repeat: no-repeat;
}

.subHeader {
    color: white;
    font-weight: bold;
    font-size: 65px;
}

@media only screen and (max-width: 575.98px) {
    body {
        margin: 0;
        padding: 0;
    }

    html {
        width: 100vw;
    }
    
    #navbarContainer, #header {
        width: 100%;
        max-width: 100%;
        margin: 0;
        padding: 20px;
    }

    #header {
        top: 0;
        margin-top: -20vw;
        width: 100%;
    }

    #navbarContainer {
        top: 0;
        padding: 0;
    }

    #mcareHeader {
        font-size: 5.5vw;
        margin-bottom: 2.75vw;
    }

    #bldgServHeader {
        font-size: 2vw;
    }

    #mcareHeader, #bldgServHeader {
        margin-left: 6vw;
    }

    #subtitle {
        font-size: 6vw;
        margin-top: 50vw;
    }

    #subtitle2 {
        font-size: 4.5vw;
    }

    .subLink {
        height: 7vw;
        font-size: 3.15vw;
    }

    #subLinkContainer {
        margin-top: 8vw;
    }

    #subHeaderContainer {
        height: 35vw;
    }

    .subHeader {
        font-size: 10vw;
    }
}